window.app.service('ReducedService', ['LIST_OF_REDUCE', function ReducedService (LIST_OF_REDUCE){
  class Reduced extends this.constructor {
    static get login () { return Boolean( ~LIST_OF_REDUCE.indexOf('login') ) }
    // static get download_project () { return Boolean( ~LIST_OF_REDUCE.indexOf('download_project') ); }
    // static get dashboard () { return Boolean( ~LIST_OF_REDUCE.indexOf('dashboard') ); }
    // static get remove_project () { return Boolean( ~LIST_OF_REDUCE.indexOf('remove_project') ); }
    // static get sync_project () { return Boolean( ~LIST_OF_REDUCE.indexOf('sync_project') ); }
    // static get search_projects () { return Boolean( ~LIST_OF_REDUCE.indexOf('search_projects') ); }
    // static get registration () { return Boolean( ~LIST_OF_REDUCE.indexOf('registration') ); }
    static get activation_existing_account () { return Boolean( ~LIST_OF_REDUCE.indexOf('activation_existing_account') ); }
    static get push_notification () { return Boolean(~LIST_OF_REDUCE.indexOf('push_notification')); }
    // static get hide_sync_dialog () { return Boolean(~LIST_OF_REDUCE.indexOf('hide_sync_dialog')); }
    // static get disable_project_item_swipe () { return Boolean(~LIST_OF_REDUCE.indexOf('disable_project_item_swipe')); }
    // static get skip_lead_autotransmission () { return Boolean(~LIST_OF_REDUCE.indexOf('skip_lead_autotransmission')); }
    // static get time_trackings () { return Boolean(~LIST_OF_REDUCE.indexOf('time_trackings')); }
    static get dot_navigation () { return Boolean(~LIST_OF_REDUCE.indexOf('dot_navigation')) }
    static get restore_password () { return Boolean(~LIST_OF_REDUCE.indexOf('restore_password')) }
    // static get project_item_control () { return Boolean(~LIST_OF_REDUCE.indexOf('project_item_control')) }
    // static get data_safe() { return Boolean(~LIST_OF_REDUCE.indexOf('data_safe')); }

    // static get statistics () {
    //   console.warn("ReducedService.statistics will deprecated");
    //   return Boolean( ~LIST_OF_REDUCE.indexOf('statistics') );
    // }
    // static get business_card () {
    //   console.warn("ReducedService.business_card will deprecated");
    //   return Boolean( ~LIST_OF_REDUCE.indexOf('business_card') );
    // }
  }

  return Reduced;
}]);
