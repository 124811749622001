/**
 * @ngdoc Services
 * @name SyncService
 * @description Connecting to the Internet and synchronization service
 */
window.app.service('SyncService', [
           '$location', 'AppFactory', 'ErrFactory', 'LoaderFactory', 'AuthService', 'ReducedService', '$timeout', '$rootScope', 'AssignmentFactory', 'HolidaysService', 'PushNotificationServices', 'PNListService',
  function ($location,   AppFactory,   ErrFactory,   LoaderFactory,   AuthService,   ReducedService,   $timeout,   $rootScope,   AssignmentFactory,   HolidaysService,   PushNotificationServices,   PNListService ) {

  let self = class Sync {
    static userSync () {
      return Promise.resolve()
      .then(() => {
        if (!AuthService.isSigning() && !ReducedService.login) throw new ErrFactory.AuthFilure;
        if (!AppFactory.isNetwork()) throw new ErrFactory.NoInternetConnectivity;
        return AuthService.authorizedUser.update();
      })
      .then(() => app.AM.runGenerator(syncProjects))
      .then(() => $rootScope.$broadcast('refreshProjects'))
      .catch((err) => {
        LoaderFactory.hide();
        LoaderFactory.hideProgress();

        if (err instanceof ErrFactory.AuthFilure) {
          err.notify();
          $location.path('/sign-in');

        // If user is locked on backend.
        } else if (err.status && err.status === 401 && !ReducedService.login) return AuthService.logout();
        else if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

    static syncAfterAppLoad () {
      return Promise.resolve()
      .then(() => {
        if (!AuthService.isSigning()) throw new ErrFactory.AuthFilure;

        if (!AppFactory.isNetwork()) {
          document.addEventListener('online', function reSync () {
            document.removeEventListener('online', reSync);
            $timeout(Sync.syncAfterAppLoad, 1000);
          });

          throw new ErrFactory.QuietError;
        }
      })
      .then(() => AppFactory.checkNewVersion())
      .then(() => {
        if ($rootScope.maintenanceMode) $rootScope.maintenanceMode = false;
        return app.AM.runGenerator(syncProjects);
      })
      .then(() => HolidaysService.requestHolidays())
      .then(() => $rootScope.$broadcast('refreshProjects'))
      .catch((err) => {
        LoaderFactory.hide();
        LoaderFactory.hideProgress();
        if (err && err.store_url) {
          $location.path('/blocker');
          if (!$rootScope.$$phase) $rootScope.$apply();
        }
        if (err instanceof ErrFactory.AuthFilure) $location.path('/sign-in');
        else if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      })
      .then(() => {
        if (window.cordova) {
          if (deviceIsAndroid) {
            startApp.getExtras(
              fields => {
                if (!$rootScope.intentComplete && fields && Object.keys(fields) && Object.keys(fields).length && fields.click_action) {
                  $rootScope.intentComplete = true;
                  PushNotificationServices.handleIntentCall(fields);
                }
                if (AuthService.authorizedUser) resetSubscription(AuthService.authorizedUser);
              },
              err => { console.error(err); }
            );
          }
          if (deviceIsIOS && AuthService.authorizedUser) resetSubscription(AuthService.authorizedUser);
        }
      })
    }

  }

  function resetSubscription(user) {
    Promise.all([
      PushNotificationServices.unsubscribeFromTopic(user.push_notifications_targets.user_email_topic),
      PushNotificationServices.unsubscribeFromTopic(user.push_notifications_targets.user_group_topic)
    ])
    .then(() => Promise.all([
      PushNotificationServices.subscribeToTopic(user.push_notifications_targets.user_email_topic),
      PushNotificationServices.subscribeToTopic(user.push_notifications_targets.user_group_topic)
    ]))
    .catch((err) => console.error(err));
  }

  function *syncProjects () {
    LoaderFactory.show();

    if (AuthService.authorizedUser) {
      yield AssignmentFactory.sync()
      .catch((err) => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });

      yield PNListService.sync()
      .then(() => $rootScope.$broadcast('unreadReload'))
      .catch((err) => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

    LoaderFactory.hide();
  }

  return self;
}]);
