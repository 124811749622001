window.app.service('RequestPermissionsService', ['ErrFactory', function ( ErrFactory ) {
  var RequestPermissions = (function(){
    const RequestPermissions: any = () => {};
    var cache = {};
    var isWindows = window.cordova && window.cordova.platformId === 'windows';

    let perm = (isWindows) ? Promise.resolve(null) : app.AM.deviceready.then(() => {
      if ( window.cordova && window.cordova.platformId === 'android' ) {
        let perm = (window.cordova && window.cordova.plugins.permissions)?  window.cordova.plugins.permissions: null;
        if (perm) {
          RequestPermissions.CAMERA  = perm.CAMERA;
          RequestPermissions.STORAGE = perm.WRITE_EXTERNAL_STORAGE;
        }

        return perm;
      } else return null;
    });

    RequestPermissions.camera = function(skipCache){
      skipCache = skipCache || false;
      return perm.then((perm) => {
        if (perm === null) return Promise.resolve();

        return RequestPermissions.check(perm.CAMERA, skipCache)
        .then(function(ans){
          if (ans.hasPermission) return Promise.resolve();
          else return Promise.reject(new ErrFactory.DeniedPermissionForCamera);
        });
      });
    }

    RequestPermissions.storage = function(skipCache){
      skipCache = skipCache || false;
      if (window.cordova && deviceIsAndroid && device && device.platform === 'Android' && +device.version >= 13) return Promise.resolve();
      return perm.then((perm) => {
        if (perm === null) return Promise.resolve();

        return RequestPermissions.check(perm.WRITE_EXTERNAL_STORAGE, skipCache)
        .then(function(ans){
          if (ans.hasPermission) return Promise.resolve();
          else return Promise.reject(new ErrFactory.DeniedPermissionForStorage);
        });
      });
    }

    RequestPermissions.has = function (type) {
      return has(type);
    }

    RequestPermissions.check = function (type, skipCache) {
      skipCache = skipCache || false;

      if (skipCache === false && cache.hasOwnProperty(type)){
        return cache[type];
      }

      cache[type] = has(type).then(function(ans){
        if ( ans.hasPermission ) {
          //cache[type] = ans;
          return Promise.resolve(ans);
        } else {
          return request(type).then(function(ans){
            //cache[type] = ans;
            return Promise.resolve(ans);
          });
        }
      });

      return cache[type];
    }

    RequestPermissions.cleanCache = function(type) {
      if (type) {
        if (cache[type]) delete cache[type];
      } else {
        cache = {};
      }
    }

    function has (type) {
      return perm.then((perm) => {
        return new Promise(function(res,rej){
          if (perm === null) return res({hasPermission: true});
          perm.checkPermission(type, res, rej);
        });
      });
    };

    function request (type){
      return perm.then((perm) => {
        return (new Promise(function(res,rej){
          perm.requestPermission(type, res, rej);
        }));
      });
    }

    return RequestPermissions;
  })();

  return RequestPermissions;
}]);
