window.app.factory('SidebarFactory', function() {
  class Sidebar extends EventEmitter {
    _isActive = false

    isActive() {
      return this._isActive;
    }

    show() {
      this._isActive = true;
      this.emitEvent('changed:state');
      return false;
    }

    hide() {
      this._isActive = false;
      this.emitEvent('changed:state');
      return false;
    }
  }

  return new Sidebar();
});
