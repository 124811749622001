window.app.service('TimeTrackingValidationService', ['AssignmentFactory', 'ValidationErrorFactory',
  function (AssignmentFactory, ValidationErrorFactory) {
  class TimeTrackingValidation {
    technicalValidation: any;
    legalValidation:     any;
    
    reportValidation:     any;
    dailyValidation:      any;
    pauseValidation:      any;
    assignmentValidation: any;

    errors: any;
    constructor() { }

    static technicalValidation() {
      return ({
        ...this.reportValidation(),
        ...this.dailyValidation(),
        ...this.pauseValidation(),
        ...this.assignmentValidation()
      });
    };

    static dailyValidation() {
      return ({
        zero_hours_time_frame: ({ daily, index }) => {
          if (daily.duration <= 0) daily.errors.push(new ValidationErrorFactory.DailyTimesError({ message: 'timeTrackings.emptyTimeFrame', index, dailyId: daily.id }));
        },
        end_after_start_day: ({ daily, index }) => {
          if (daily.start_date >= daily.end_date) daily.errors.push(new ValidationErrorFactory.DailyEndTimesError({ message: 'timeTrackings.endTimeisBeforeError', index, dailyId: daily.id, dailyEnd: true }));
        },

        no_future_reports: ({ daily, index }) => {
          if (daily.start_date.getTime() > new Date().getTime()) daily.errors.push(new ValidationErrorFactory.DailyError({ message: 'timeTrackings.noFutureActivityReport', index, dailyId: daily.id }));
        }
      });
    };

    static reportValidation() {
      return ({
        one_report_per_day: ({daily, index, ttList}) => {
          let duplicate = ttList.filter(tf => tf.assignment.id === daily.assignment.id)
                                .filter(tf => daily.id ? tf.id !== daily.id: true)
                                .find(tf => tf.start_date.getDate() === daily.start_date.getDate());
          if (duplicate) daily.errors.push(new ValidationErrorFactory.DailyError({ message: 'activityReport.one_report_per_day_error', index, dailyId: daily.id }));
        },
        daily_timeslot: ({ daily, ar, index }) => {
          if (ar) {
            let dailyDay = new Date(daily.start_date);
            let nextDailyDay = new Date(daily.end_date);
            dailyDay.setHours(0,0,0,0);
            nextDailyDay.setHours(0,0,0,0);
            let timeslotBooked = ar.dailyReportList.filter(item => {
              if (daily.id) return item.id !== daily.id;
              else if (daily.validation_id) return item.validation_id !== daily.validation_id;
              return true;
            })
            .filter(item => {
              if (!item.end_date) return false;
              let itemDay = new Date(item.start_date);
              let nextItemDay = new Date(item.end_date);
              itemDay.setHours(0,0,0,0);
              nextItemDay.setHours(0,0,0,0);
              return itemDay.getTime()     === dailyDay.getTime() || itemDay.getTime()     === nextDailyDay.getTime() ||
              nextItemDay.getTime() === dailyDay.getTime() || nextItemDay.getTime() === nextDailyDay.getTime();
            }).find(item => (daily.start_date.getTime() <= item.start_date.getTime() && daily.end_date.getTime() >= item.start_date.getTime()) ||
                            (daily.start_date.getTime() >= item.start_date.getTime() && daily.end_date.getTime() <= item.end_date.getTime())   ||
                            (daily.start_date.getTime() <= item.end_date.getTime()   && daily.end_date.getTime() >= item.end_date.getTime())   ||
                            (daily.start_date.getTime() <= item.start_date.getTime() && daily.end_date.getTime() >= item.end_date.getTime())
            );
            if (timeslotBooked) daily.errors.push(new ValidationErrorFactory.DailyError({ message: 'timeTrackings.bookedTimeslot', index, dailyId: daily.id }));
          }
        },

        report_status_empty: ({ ar, confirmAr }) => { if (ar && confirmAr) {
          if (!ar.errors) ar.errors = [];
          if (!ar.reportStatus) ar.errors.push(new ValidationErrorFactory.StatusError({ message: 'errors.selectReleaseOption'}));
        }},
        signature_empty: ({ ar, confirmAr }) => { if (ar && confirmAr && ar.reportStatus && ar.reportStatus.status === 1 && !ar.signature ) {
          if (!ar.errors) ar.errors = [];
          ar.errors.push(new ValidationErrorFactory.SignatureError({ message: 'timeTrackings.externalEmployeeSignatureError'}));
        }},
        // signer_empty: ({ ar, confirmAr }) => { if (ar && confirmAr && ar.reportStatus && ar.reportStatus.status === 1 && !ar.signer ) {
        //   if (!ar.errors) ar.errors = [];
        //   ar.errors.push(new ValidationErrorFactory.SignatureError({ message: 'timeTrackings.externalEmployeeSignerError'}));
        // }}
      });
    };

    static pauseValidation() {
      return ({
        pause_not_entered: ({ daily, index }) => {
          daily.pauses.forEach((pause, pIndex) => {
            if (!pause.start && !pause.end) pause.errors.push(new ValidationErrorFactory.DailyPauseError({      message: 'timeTrackings.pausesWithoutStartEnd', index, pIndex, dailyId: daily.id, pauseId: pause.id }));
            if (!pause.start &&  pause.end) pause.errors.push(new ValidationErrorFactory.DailyPauseStartError({ message: 'timeTrackings.pausesWithoutStart',    index, pIndex, dailyId: daily.id, pauseId: pause.id, pauseStart: true }));
            if ( pause.start && !pause.end) pause.errors.push(new ValidationErrorFactory.DailyPauseEndError({   message: 'timeTrackings.pausesWithoutEnd',      index, pIndex, dailyId: daily.id, pauseId: pause.id, pauseEnd:   true }));
          });
        },
        pause_not_within_day: ({ daily, index }) => {
          daily.pauses.forEach((pause, pIndex) => {
            if (pause.start && (daily.start_date > pause.start || daily.end_date < pause.start)) pause.errors.push(new ValidationErrorFactory.DailyPauseStartError({ message: 'timeTrackings.pausesWithinWorkingTime', index, dailyId: daily.id, pauseId: pause.id, pauseStart: true }));
            if (pause.end   && (daily.start_date > pause.end   || daily.end_date < pause.end))   pause.errors.push(new ValidationErrorFactory.DailyPauseEndError({   message: 'timeTrackings.pausesWithinWorkingTime', index, dailyId: daily.id, pauseId: pause.id, pauseEnd:   true }));
          });
        },
        end_pause_before_start_pause: ({ daily, index }) => {
          daily.pauses.forEach((pause, pIndex) => {
            if (pause.end <= pause.start) pause.errors.push(new ValidationErrorFactory.DailyPauseEndError({ message: 'timeTrackings.pausesWithoutStartEnd', index, pIndex, dailyId: daily.id, pauseId: pause.id, pauseEnd: true }));
          });
        },
        start_pause_inside_other_pauses: ({ daily, index }) => {
          let pauses = [...daily.pauses];
          pauses.forEach((pause, pIndex) => {
            let err = pauses.filter((item, lindex) => lindex != pIndex)
            .filter(item => item.start && item.end)
            .find(item => pause.start.getTime() <= item.start.getTime() && pause.end > item.start  );
            if (err) pause.errors.push(new ValidationErrorFactory.DailyPauseError({ message: 'timeTrackings.pausesAreOverlappedError', index, pIndex, dailyId: daily.id, pauseId: pause.id }));
          });
        },
        // set_pause_before_existing: (daily, pause) => {
        //   daily.pauses.forEach((p) => {
        //     if (pause.start <= p.start) pause.errors.push(new ValidationErrorFactory.TechnicalError({ message: 'timeTrackings.pauseBeroreExisting', index, dailyId: daily.id, pauseId: pause.id }));
        //   })
        // },
        // end_day_after_start_last_pause: ({ daily, index }) => {
        //   if ((daily.lastPause && daily.lastPause.start) > daily.end_date) daily.errors.push(new ValidationErrorFactory.DailyEndTimesError({ message: 'timeTrackings.endTimeBeforePauseStart', index, dailyId: daily.id, dailyEnd: true }));
        //   if ((daily.lastPause && daily.lastPause.end)   > daily.end_date) daily.errors.push(new ValidationErrorFactory.DailyEndTimesError({ message: 'timeTrackings.endTimeisBeforeError',            index, dailyId: daily.id, dailyEnd: true }));
        // },
        end_pause_inside_other_pauses: ({ daily, index }) => {
          let pauses = [...daily.pauses];
    
          daily.pauses.forEach((pause, pIndex) => {
            let err = pauses.filter((_, kindex) => kindex != pIndex)
            .find(item =>
              (item.start  < pause.start && item.end  > pause.start) ||
              (item.start  < pause.end   && item.end  > pause.end)   ||
              (pause.start < item.start  && pause.end > item.end)
            );
            if (err) pause.errors.push(new ValidationErrorFactory.DailyPauseEndError({ message: 'timeTrackings.pausesAreOverlappedError', index, pIndex, dailyId: daily.id, pauseId: pause.id, pauseEnd: true }));
          });
        }
      })
    };

    static assignmentValidation() {
      return ({
        reports_within_a_job: ({ daily, index }) => {
          if (daily.start_date < daily.assignment.starts_at) {
            daily.errors.push(new ValidationErrorFactory.DailyError({ message: 'activityReport.reports_within_a_job_start_error', messageOptions: { jobStart: daily.assignment.starts_at.toLocaleDateString() }, index, dailyId: daily.id }));
          } else if (daily.start_date > daily.assignment.ends_at) {
            daily.errors.push(new ValidationErrorFactory.DailyError({ message: 'activityReport.reports_within_a_job_end_error',   messageOptions: { jobEnd:   daily.assignment.ends_at.toLocaleDateString()   }, index, dailyId: daily.id }));
          }
        },
        assignment_selected: ({ daily, index }) => {
          if (!(daily.assignment instanceof AssignmentFactory)) daily.errors.push(new ValidationErrorFactory.DailyError({ message: 'timeTrackings.assignmentMissing', index, dailyId: daily.id }));
        },
        reports_within_a_week: ({ daily, ar, index }) => {
          if (ar && (daily.start_date < ar.startDate || daily.start_date > ar.endDate)) daily.errors.push(new ValidationErrorFactory.DailyError({ message: 'activityReport.reports_within_a_week_error', index, dailyId: daily.id }));
        }
      });
    };

    static legalValidation() {
      return ({
        single_pause_duration: ({ daily, index }) => {
          daily.pauses.forEach((pause, pIndex) => {
            let pauseDuration = (pause.end - pause.start) / 60000;
            if (pause.start && pause.end && pauseDuration < 15) pause.errors.push(new ValidationErrorFactory.DailyPauseLegalError({ message: 'timeTrackings.pauseMin15', index, pIndex, dailyId: daily.id, pauseId: pause.id }));
          });
        },
        total_pauses_time: ({ daily, index }) => {
          let workingTime = daily.totalDurationTime - daily.pausesDuration;
          let nineHours = 9*60*60*1000;
          let sixHours  = 6*60*60*1000;
    
          let longPause = 45*60*1000;
          if (workingTime > nineHours && daily.pausesDuration < longPause)
            daily.errors.push(new ValidationErrorFactory.LegalError({ message: 'timeTrackings.workTimeMore9', index, dailyId: daily.id }));
          
          let shortPause = 30*60*1000;
          if (workingTime <= nineHours && workingTime > sixHours && daily.pausesDuration < shortPause) {
            daily.errors.push(new ValidationErrorFactory.LegalError({ message: 'timeTrackings.workTimeMore6', index, dailyId: daily.id }));
          }
        },
        max_working_hours: ({ daily, index }) => {
          let maxWorkingHours = 10*60*60*1000; // max 10 hours per day
          if (daily.duration > maxWorkingHours) daily.errors.push(new ValidationErrorFactory.LegalError({ message: 'timeTrackings.workTimeMore10', index, dailyId: daily.id }));
        }
      });
    }

    static validateAR(ar, confirmAr): void {
      ar.dailyReportList.forEach((daily, index) => {
        for (let key in this.technicalValidation()) { this.technicalValidation()[key]({ daily, ar, index, ttList: ar.dailyReportList, confirmAr }); }
        for (let key in this.legalValidation())     { this.legalValidation()[key]({ daily, ar, index }); }
      });
    }

    static validateDaily(daily, ttList = null): void {
      for (let key in this.technicalValidation()) { this.technicalValidation()[key]({ daily, ttList }); }
      for (let key in this.legalValidation())     { this.legalValidation()[key]({ daily }); }
    }

  }
  return TimeTrackingValidation;
}]);
