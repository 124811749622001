/**
 * @ngdoc Services
 * @name ValidateService
 * @description Validate field BankAccount service
 */
app.service('ValidateService', function () {

  this.isEmailValid = function(emailAddress) {
    let reg = new RegExp('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$');
    return reg.test(emailAddress);
  }

  return this;
});
