/**
 * @ngdoc Services
 * @name SettingsService
 * @description Settings Currency Service
 */
app.service('SettingsService', function () {
  var self = this;

  self.getModalPopupStyles = function () {

    var options = {
      maxWidth: 600,
      maxHeight: 600,
      minMarginLeft: 20,
      minMarginTop: 50,
      heightHeader: 62,
      heightFooter: 52
    };

    var vw = window.innerWidth;
    var vh = window.innerHeight;

    var windowWidth = vw - (options.minMarginLeft * 2);
    var marginLeft = options.minMarginLeft;

    if (windowWidth > options.maxWidth) {
      windowWidth = options.maxWidth;
      marginLeft = (vw - windowWidth) / 2;
    }

    return {
      windowWidth: windowWidth,
      marginLeft: marginLeft
    };
  };

  return self;
});
