window.app.service('ReleasesService', ['API', 'ErrFactory', function (API, ErrFactory) {
  class Release {
    constructor() {}

    static getReleases(page) {
      return Promise.resolve(API.getReleases(page))
      .catch(err => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

  }

  return Release;
}]);
