window.app.service('VacationRequestService', ['API', 'ErrFactory', function (API, ErrFactory) {
  class VacationRequest {
    constructor() {}

    static getVacationRequestsOverview(page, column, dir, filter) {
      let query = '&q[vacation_requests][exclude_state]=awaiting_external_approval';
      query = filter ? query+`&q[vacation_requests][approval_state]=${filter}` : query;
      return Promise.resolve(API.getVacationRequests(page, column, dir, query))
      .catch(err => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

    static getOpenVacationRequests() {
      return Promise.resolve(API.getOpenVacationRequests())
      .catch(err => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

    static getVacationRequestById(id: number) {
      return Promise.resolve(API.getVacationRequestById(id))
      .catch(err => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

    static updateVacationRequest(id: number, data: any) {
      return Promise.resolve(API.updateVacationRequest(id, data))
      .catch(err => {
        if (err instanceof ErrFactory) err.notify();
        else console.error(err);
      });
    }

  }

  return VacationRequest;
}]);
